import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "@fontsource/rajdhani";
import { PaystackButton } from "react-paystack";
import { Toaster, toast } from "react-hot-toast";
import "./esport.css";
import "./esport_mobile.css";
import Logo from "./images/eSport.png";
import SubmitButton from "./images/submit_button.png";
import ProceedButton from "./images/proceed_button.png";
import MyPaystackButton from "./images/paystack_button.png";
// import mk from "./images/mk.png";
import mk_desktop from "./images/mk_desktop.webp";
import mk_mobile from "./images/mk_mobile.webp";
import fifa_desktop from "./images/fifa_desktop.webp";
import fifa_mobile from "./images/fifa_mobile.webp";
import cod_desktop from "./images/cod_desktop.webp";
import cod_mobile from "./images/cod_mobile.webp";
import textfield_img from "./images/textfield_img.png";
import Line from "./images/footer_line1.png";
import left_dash from "./images/left_dash.png";
import middle_dash from "./images/middle_dash.png";
import right_dash from "./images/right_dash.png";
import bar_lines from "./images/lines.png";
import dots from "./images/dots.png";
import add_icon from "./images/add.png";
import agree_icon from "./images/selected.png";
import unagree_icon from "./images/unselected.png";
import footer_line from "./images/footer_line.png";
import footer_line1 from "./images/footer_line1.png";
import eSport_cp from "./images/eSport_cp.png";
import footer_frame from "./images/footer_frame.png";
import game_choice from "./images/game_choice.png";
import payment_bg from "./images/payment_bg.png";
import matchup_desktop from "./images/matchup_desktop.png";
// import Lottie from 'react-lottie-player'
import Lottie from "lottie-react";
import loadingData from "./images/loading.json";
import Select from "react-select";

export default function ESport() {
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [stage, setStage] = useState("stage1");
  // const [stage, setStage] = useState("stage2");
  // const [stage, setStage] = useState("stage3");
  const [paymentMethod, setPaymentMethod] = useState("transfer");

  function clear_fields() {
    setFullame("");
    setEmail("");
    setUserphone("");
    setIGN("");
    setTeamname("");
    setSelectedStudentFile(null);
    setSelectedStudentSrc("");
    console.log("cleared entries");
  }

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const [fullname, setFullame] = useState("");
  const handleFullnameChange = (event) => {
    setFullame(event.target.value);
  };

  const [email, setEmail] = useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const [userphone, setUserphone] = useState("");
  const handlePhoneChange = (event) => {
    setUserphone(event.target.value);
  };

  const [ign, setIGN] = useState("");
  const handleIGNChange = (event) => {
    setIGN(event.target.value);
  };

  const [teamname, setTeamname] = useState("");
  const handleTeamnameChange = (event) => {
    setTeamname(event.target.value);
  };

  const [selectedStudentFile, setSelectedStudentFile] = useState(null);
  const [selectedStudentSrc, setSelectedStudentSrc] = useState("");
  const changeStudentPicture = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedStudentSrc(reader.result);
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedStudentFile(e.target.files[0]);
    // setselectedStudentFile!=null(true);
  };

  const options = [
    { value: "mk", label: "MORTAL KOMBAT 1" },
    { value: "codm", label: "Call Of Duty - Mobile" },
    { value: "fifa", label: "EA FIFA 24" },
  ];
  const [selectedOption, setSelectedOption] = useState({
    value: "mk",
    label: "MORTAL KOMBAT 1",
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "2px solid #4A90E2" : "1px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #4A90E2",
      },
    }),
    // Add more styles for other components as needed
  };

  let current_year = new Date().getFullYear();

  // const [showButton, setShowButton] = useState("paystack-button");
  // const email = email;
  // const email = "osagiepromise79@gmail.com";
  // const name = fullname;
  // const name = "eSport";
  // const phone = userphone;
  // const phone = "09162627332";

  const input_style_mobile = {
    position: "relative",
    left: "5px",
    bottom: "25px",
    width: "80%",
    fontSize: "1rem",
    fontFamily: "Rajdhani",
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    color: "white",
  };

  const input_style_desktop = {
    position: "relative",
    left: "20px",
    bottom: "45px",
    width: "100%",
    fontSize: "25px",
    fontFamily: "Rajdhani",
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    color: "white",
  };

  // obinna
  //  secret key: pk_test_b3762353b3dbe48af2d6040023a97e0b37bfb7b4
  //  public key: pk_test_dfa0ccb7b9f8ec8f81d51b0185a48e261a025294

  //ESPORT
  // publicKey: "pk_test_92942699391bff6e5405517446654cf7e5dfb3aa",

  const price = 3000;
  const amount = price * 100;

  const showErrorToast = (message) => {
    toast.error(message, {
      icon: "❗️🎮️❗️",
      position: "top-center",
    });
  };

  const componentProps = {
    email,
    amount, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    metadata: {
      fullname,
      userphone,
    },
    channels: ["card"],
    publicKey: "pk_test_dfa0ccb7b9f8ec8f81d51b0185a48e261a025294",
    className: "paystack-button",
    text: "Pay NGN 3,000",
    reference: new Date().getTime().toString(),
    onSuccess: () => {
      register_player("card");
      // setStage("stage3");
    },
    // callback: function
    // onFail: () => {},

    onClose: () => {
      showErrorToast("Payment cancelled!");
    },
  };

  const register_player = async (payment_method) => {
    setLoading(true);

    const formData = new FormData();

    let date = new Date();

    const game_choice = selectedOption.label;


    formData.append("fullname", fullname);
    formData.append("phone_no", userphone);
    formData.append("email", email);
    formData.append("ign", ign);
    formData.append("teamName", teamname);
    formData.append("payment_method", payment_method);
    formData.append("game_choice", game_choice);
    formData.append("reg_day", date.getDate());
    formData.append("reg_month", date.getMonth() + 1);
    formData.append("reg_year", date.getFullYear());
    formData.append("payment_status", "success");
    formData.append("image", selectedStudentFile);

    for (const pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: formData,
      url: "https://server-deebug-esport.onrender.com/user_registration/register",
    };

    axios(options)
      .then((response) => {
        console.log(`--------> ${response}`);
        setLoading(false);
        if (response.status == 200) {
          setStage("stage3");
        } else {
          alert(
            "An error occured while creating your account.\nPlease try again"
          );
        }
      })
      .catch((e) => {
        console.log(`--------> ${e.response}`);

        // if (
        //   e.response.data["msg"] ==
        //   "An account with this in game name already exists"
        // ) {
        //   showErrorToast("A user with this IGN already exists");
        // } else {
        showErrorToast(
          "The server encountered an error.\nPlease try again later"
        );
        // }
        setLoading(false);
      });
  };

  // const body1 = {
  //   backgroundImage: "url('./images/top.webp')",
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   /* background-position: fixed; */
  // };
  // const body2 = {
  //   backgroundImage: "url('./images/bottom.webp')",
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  // };
  // const body3 = {
  //   backgroundImage: "url('./images/third.webp')",
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  // };

  // const font_style = {
  //   fontFamily: "Rajdhani, sans-serif",
  // };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div className="title-img">
        <img
          className="container"
          src={dots}
          alt="dots"
          style={{ objectFit: "cover" }}
        />
        <img
          src={Logo}
          alt="Logo"
          placeholder={Logo}
          style={{
            objectFit: "contain",
            width: "12vw",
            // width: "12%",
            // height: "200px",
            // width: "200px",
            position: mobile ? "fixed" : "absolute",
            paddingLeft: mobile ? "0" : "50px",
          }}
        />
      </div>

      <>
        <img
          className="bar-styling"
          src={bar_lines}
          alt="..."
          style={{ objectFit: "contain", height: mobile ? "50%" : "800px" }}
        />
      </>

      <div className="title-div">
        <h1
          style={
            mobile
              ? { color: "white", fontSize: "5vw", width: "100%" }
              : { color: "white", fontSize: "3vw" }
          }
        >
          Register for a Tournament
        </h1>

        <div className="button-stack">
          <img
            src={game_choice}
            alt="Select Game"
            // placeholder={Submit}
            style={
              mobile
                ? { objectFit: "contain", width: "100%" }
                : { objectFit: "contain", width: "30vw", paddingTop: "30px" }
            }
          />

          <Select
            options={options}
            isSearchable={ false }
            styles={{
              // handles the color of the selected text
              singleValue: (provided, state) => ({
                ...provided,
                color: "white",
              }),
              
              control: (baseStyles, state) => ({
                ...baseStyles,
                // borderColor: state.isFocused ? "grey" : "red",
                fontSize: mobile ? "3.5vw" : "1.6vw",
                border: state.isFocused ? "none" : "none",
                outline: state.isFocused ? "none" : "none",
                backgroundColor: "transparent",
                // paddingTop: mobile ? "25px" : "5px",
                paddingLeft: "50px",
                position: "relative",
                bottom: mobile ? "36px" : "50px",
                color: "white",
              }),

              menu: (provided, state) => ({
                ...provided,
                backgroundColor: "#2f2221",
                color: "white",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: "transparent",
                color: state.isSelected ? "grey" : "white",
                "&:hover": {
                  backgroundColor: "red",
                  color: "#ffffff",
                },
              }),
            }}
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            placeholder="Select Game"
          />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="headline-items">
          <p
            style={
              mobile
                ? {
                    color: stage == "stage1" ? "white" : "grey",
                    fontSize: stage == "stage1" ? "5vw" : "3.5vw",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }
                : {
                    color: stage == "stage1" ? "white" : "grey",
                    fontSize: stage == "stage1" ? "2vw" : "1.5vw",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }
            }
            onClick={() => {
              clear_fields();
              setStage("stage1");
            }}
          >
            Registration
          </p>
          <p
            style={
              mobile
                ? {
                    color: stage == "stage2" ? "white" : "grey",
                    fontSize: stage == "stage2" ? "5vw" : "3.5vw",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }
                : {
                    color: stage == "stage2" ? "white" : "grey",
                    fontSize: stage == "stage2" ? "2vw" : "1.5vw",
                    fontWeight: "bold",
                  }
            }
          >
            Payments
          </p>
          <p
            style={
              mobile
                ? {
                    color: stage == "stage3" ? "white" : "grey",
                    fontSize: stage == "stage3" ? "5vw" : "3.5vw",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }
                : {
                    color: stage == "stage3" ? "white" : "grey",
                    fontSize: stage == "stage3" ? "2vw" : "1.5vw",
                    fontWeight: "bold",
                  }
            }
          >
            Match up
          </p>
        </div>
      </div>

      <img src={footer_line1} className="title-divider" />

      {mobile && stage !== "stage3" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={
              selectedOption.value == "codm"
                ? cod_mobile
                : selectedOption.value == "fifa"
                ? fifa_mobile
                : mk_mobile
            }
            // src={fifa_desktop}
            // src={mk_desktop}
            alt="game"
            // placeholder={mk}
            style={{ objectFit: "contain", height: "35vh" }}
          />
        </div>
      ) : null}

      {/* <div
        style={{
          height: "0.8px",
          width: "100%",
          backgroundColor: "rgba(247, 80, 73, 0.5)",
        }}
      ></div> */}

      {stage !== "stage3" ? (
        <div className="row-container">
          {/* FORM */}

          {stage == "stage1" ? (
            <div className="form-styling">
              <div className="label-textfield">
                <p className="text-style">Name</p>
                <div className="button-stack">
                  <img
                    src={textfield_img}
                    alt="..."
                    className="image-positioning-style"
                  />
                  <input
                    className="custom-input"
                    maxLength={50}
                    onChange={handleFullnameChange}
                  ></input>
                </div>
              </div>

              <div className="label-textfield">
                <p className="text-style">Email</p>
                <div className="button-stack">
                  <img
                    src={textfield_img}
                    alt="..."
                    className="image-positioning-style"
                  />
                  <input
                    className="custom-input"
                    onChange={handleEmailChange}
                    maxLength={50}
                  ></input>
                </div>
              </div>

              <div className="label-textfield">
                <p className="text-style">Phone Number</p>
                <div className="button-stack">
                  <img
                    src={textfield_img}
                    alt="..."
                    className="image-positioning-style"
                  />
                  <input
                    className="custom-input"
                    onChange={handlePhoneChange}
                    maxLength={11}
                  ></input>
                </div>
              </div>

              <div className="label-textfield">
                <p className="text-style">IGN (In Game Name)</p>
                <div className="button-stack">
                  <img
                    src={textfield_img}
                    alt="..."
                    className="image-positioning-style"
                  />
                  <input
                    className="custom-input"
                    onChange={handleIGNChange}
                    maxLength={50}
                  ></input>
                </div>
              </div>

              <div className="label-textfield">
                <p className="text-style">Team Name (Optional)</p>
                <div className="button-stack">
                  <img
                    src={textfield_img}
                    alt="..."
                    className="image-positioning-style"
                  />
                  <input
                    className="custom-input"
                    onChange={handleTeamnameChange}
                    // style={mobile?{fontSize:"1rem"}:{fontSize:"25px"}}
                    maxLength={50}
                  ></input>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  className="add-image"
                  style={{
                    height: "40px",
                    width: "40px",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  <label htmlFor="fileInput">
                    <img
                      src={add_icon}
                      alt="add icon"
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    />
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={changeStudentPicture}
                    style={{ display: "none" }}
                  />
                </div>

                <p
                  style={{ color: "white", fontSize: mobile ? "12px" : "18px" }}
                >
                  Click here to Upload Image
                </p>
              </div>

              {selectedStudentFile != null ? (
                <div className="data-img">
                  <img
                    src={selectedStudentSrc}
                    alt=""
                    style={
                      mobile
                        ? {
                            objectFit: "cover",
                            margin: "10px",
                            height: "150px",
                            width: "150px",
                          }
                        : {
                            objectFit: "cover",
                            margin: "0 10px",
                            height: "200px",
                            width: "200px",
                          }
                    }
                  />
                </div>
              ) : (
                <p style={{ color: "white", fontSize: "18px" }}>
                  Upload Image/Photo of Player
                </p>
              )}

              {/* AGREE */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  className="add-image"
                  style={{
                    height: "40px",
                    width: "40px",
                    cursor: "pointer",
                    marginRight: mobile ? "-5px" : "10px",
                  }}
                >
                  <img
                    src={agree == false ? unagree_icon : agree_icon}
                    alt="icon"
                    onClick={() => {
                      setAgree(!agree);
                    }}
                    style={
                      mobile
                        ? {
                            paddingTop: "8px",
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                          }
                        : {
                            height: "30px",
                            width: "30px",
                            cursor: "pointer",
                          }
                    }
                  />
                </div>

                <p
                  style={{
                    color: "white",
                    fontSize: mobile ? "12px" : "18px",
                    paddingRight: "5px",
                  }}
                >
                  I Agree to
                </p>

                <p
                  style={{
                    color: "#5EF6FF",
                    fontSize: mobile ? "12px" : "18px",
                    cursor: "pointer",
                  }}
                >
                  terms and conditions
                </p>
              </div>

              {/* look at edit student passport in olhms */}
              <img
                className="submit-button"
                src={ProceedButton}
                alt="button"
                // placeholder={Submit}
                onClick={() => {
                  setStage("stage2");

                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                  setStage("stage2");

                  // if (fullname !== "") {
                  //   if (email.length > 8 && email.includes("@")) {
                  //     if (userphone.length == 11) {
                  //       if (ign !== "") {
                  //         if (selectedStudentFile !== null) {
                  //           if (agree == true) {
                  //             setStage("stage2");

                  //             // var screenHeight = window.innerHeight; // Get the height of the viewport
                  //             // var scrollYPosition = screenHeight * 0.4; // Scroll to 50% of the screen height
                  //             window.scrollTo({
                  //               top: 0,
                  //               left: 0,
                  //               behavior: "smooth",
                  //             });

                  //             // window.scrollTo(0, 0,);
                  //           } else {
                  //             showErrorToast(
                  //               "Please agree to terms & conditions\nbefore proceeding"
                  //             );
                  //           }
                  //         } else {
                  //           showErrorToast("Please add your picture");
                  //         }
                  //       } else {
                  //         showErrorToast("Please enter your In Game Name");
                  //       }
                  //     } else {
                  //       showErrorToast("Check your phone number properly");
                  //     }
                  //   } else {
                  //     showErrorToast("Use a valid email");
                  //   }
                  // } else {
                  //   showErrorToast("Please enter your name");
                  // }
                }}
              />
            </div>
          ) : stage == "stage2" ? (
            <div className="form-styling">
              <p
                style={{
                  color: "white",
                  fontSize: mobile ? "1.2rem" : "30px",
                  fontWeight: "bold",
                  margin: "0",
                  paddingBottom: "10px",
                }}
              >
                Payment Method
              </p>

              {/* tailwind */}
              {/* <div class="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  class="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="flexRadioDefault"
                  id="radioDefault01"
                />
                <label
                  class="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  for="radioDefault01"
                >
                  Default radio
                </label>
              </div>
              <div class="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  class="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="flexRadioDefault"
                  id="radioDefault02"
                  checked
                />
                <label
                  class="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  for="radioDefault02"
                >
                  Default checked radio
                </label>
              </div> */}

              {/* normal */}
              <div>
                <label
                  style={{
                    color: "white",
                    paddingRight: "40px",
                    fontSize: mobile ? "1rem" : "20px",
                  }}
                >
                  <input
                    type="radio"
                    value="transfer"
                    checked={paymentMethod == "transfer"}
                    onChange={handlePaymentChange}
                  />
                  Transfer
                </label>

                <label
                  style={{ color: "white", fontSize: mobile ? "1rem" : "20px" }}
                >
                  <input
                    type="radio"
                    value="card"
                    checked={paymentMethod === "card"}
                    onChange={handlePaymentChange}
                  />
                  Card
                </label>
              </div>

              {paymentMethod == "transfer" ? (
                <>
                  <p
                    style={{
                      color: "white",
                      fontSize: mobile ? "1.1rem" : "22px",
                      margin: "0",
                      paddingTop: "30px",
                      paddingBottom: "10px",
                    }}
                  >
                    Send Payment to the Account Below
                  </p>

                  <div className="payment-stack">
                    <img
                      src={payment_bg}
                      alt=""
                      style={{
                        height: mobile ? "70%" : "110px",
                        width: mobile ? "90%" : "60%",
                        // marginBottom: "10px",
                      }}
                    />
                    <div
                      style={{
                        color: "#5EF6FF",
                        fontSize: mobile ? "1.1rem" : "22px",
                        position: "absolute",
                        paddingLeft: "20px",
                        // bottom: "60px",
                      }}
                    >
                      <p style={{ margin: "0" }}>Keystone Bank</p>
                      <p style={{ margin: "0" }}>Account Number: 1012313474</p>
                      <p style={{ margin: "0" }}>
                        Account Name: Deebug Studios
                      </p>
                    </div>
                  </div>

                  <p
                    style={{
                      color: "red",
                      fontSize: mobile ? "0.8rem" : "22px",
                      margin: "0",
                      paddingTop: "5px",
                    }}
                  >
                    Important: Send Proof of Payment to{" "}
                    <span
                      style={{ cursor: "pointer", color:"green" }}
                      onClick={() => {
                        const phoneNumber = "+2309032183622";

                        // Construct the WhatsApp URL with the phone number
                        const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
                          phoneNumber
                        )}`;

                        // // Redirect the user to WhatsApp
                        // window.location.href = whatsappUrl;

                        // Open WhatsApp in a new window
                        window.open(whatsappUrl, "_blank");
                      }}
                    >
                      {" "}
                      '09032183622'
                    </span>{" "}
                    via Whatsapp
                  </p>
                </>
              ) : null}

              <div style={{ display: "flex", flexDirection: "row" }}>
                {paymentMethod == "transfer" ? (
                  <>
                    <img
                      src={SubmitButton}
                      alt=""
                      className="submit-button"
                      onClick={() => {
                        register_player("transfer");
                        // setStage("stage3");
                      }}
                    />
                  </>
                ) : (
                  <div>
                    <PaystackButton {...componentProps} />
                    <p
                      style={{
                        color: "white",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                    >
                      * via paystack
                    </p>
                  </div>
                )}
                {loading === true ? (
                  <Lottie
                    animationData={loadingData}
                    loop={true}
                    style={{ height: "30%", width: "30%" }}
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          {/* IMAGE */}
          {mobile ? null : (
            <img
              src={
                selectedOption.value == "codm"
                  ? cod_desktop
                  : selectedOption.value == "fifa"
                  ? fifa_desktop
                  : mk_desktop
              }
              // src={fifa_desktop}
              // src={mk_desktop}
              alt="game"
              // placeholder={mk}
              style={{ objectFit: "contain", width: "50%" }}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            width: "90%",
            margin: "auto", // Center the div horizontally
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center the content vertically
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: mobile ? "1rem" : "30px",
              paddingRight: "5px",
              maxWidth: "100%",
              paddingLeft: "30px",
              textAlign: "left",
            }}
          >
            {" "}
            Form Submitted Successfully, you’ll receive an email detailing
            information about the Event schedule and Ticket once payment is
            confimed. Refresh this Page on{" "}
            <span style={{ color: "#5ef6ff" }}>December 18, 2023</span> to view
            Roaster/ Matchup.
          </p>

          <img
            src={matchup_desktop}
            alt="."
            style={{ objectFit: "cover", width: "100%" }}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: mobile ? "30px 0" : null,
        }}
      >
        <div className="headline-items">
          <img
            src={left_dash}
            alt="."
            style={{ objectFit: "contain", width: mobile ? "10px" : "20px" }}
          />
          <img
            src={middle_dash}
            alt="."
            style={{ objectFit: "contain", width: mobile ? "20px" : "30px" }}
          />
          <img
            src={right_dash}
            alt="."
            style={{ objectFit: "contain", width: mobile ? "10px" : "20px" }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: mobile ? "0 15px 0 50px" : "0 100px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img
              src={eSport_cp}
              alt="."
              style={{
                objectFit: "contain",
                width: mobile ? "20px" : "50px",
                paddingRight: "10px",
              }}
            />
            <p style={{ color: "#F75049", fontSize: mobile ? "0.6rem" : null }}>
              DEEBUG ESPORTS
            </p>
          </div>
          <p
            style={{
              color: "#F75049",
              fontSize: mobile ? "0.6rem" : "12px",
              paddingBottom: "5px",
              margin: "0",
            }}
          >
            KINDLY READ OUR COMMUNITY RULES AND GUIDELINES
          </p>
          <p
            style={{
              color: "#F75049",
              fontSize: mobile ? "0.6rem" : "12px",
              padding: "0",
              margin: "0",
            }}
          >
            BEFORE ENGAGING IN A TOURNAMENT
          </p>
        </div>
        <img
          src={footer_frame}
          alt="."
          style={{ objectFit: "contain", width: mobile ? "150px" : "250px" }}
        />
      </div>

      <img src={footer_line} style={{ objectFit: "contain", width: "100%" }} />

      <div
        style={
          mobile
            ? {
                display: "flex",
                fontSize: "0.6rem",
                justifyContent: "space-between",
                padding: "0 10px",
                marginBottom: "20px",
              }
            : {
                display: "flex",
                justifyContent: "space-between",
                padding: "0 100px",
                marginBottom: "50px",
              }
        }
      >
        {mobile ? (
          <p style={{ color: "#F75049" }}>
            CONTACT US:
            <br /> 09032183622
          </p>
        ) : (
          <p style={{ color: "#F75049" }}>CONTACT US: 09032183622</p>
        )}
        {mobile ? (
          <p style={{ color: "#F75049", textAlign: "center" }}>
            DEEBUG ESPORTS ™ {current_year}
            <br />
            ALL RIGHTS RESERVED
          </p>
        ) : (
          <p style={{ color: "#F75049", textAlign: "center" }}>
            DEEBUG ESPORTS ™ {current_year} ALL RIGHTS RESERVED
          </p>
        )}
        <p style={{ color: "#F75049" }}>PRIVACY POLICY</p>
      </div>
    </div>
  );
}

// return (
//   <div
//     style={{
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//     }}
//   >
//     <div className="paystack-button">
//       <PaystackButton {...componentProps} />
//     </div>
//   </div>
// );
